// @flow

// TODO: Read default locale from contentful. Figure out how to do cleanly
const DEFAULT_LOCALE = "en";

/**
 * Map assets to include the ensured fields created in onCreateNode
 */
export default (entry: *, context: *) => {
    const { previewClient } = context;

    // Return same if defined
    if (entry.file || entry.locale === DEFAULT_LOCALE) {
        return {
            ...entry,
            fields: {
                ensured: entry,
            },
        };
    }

    return previewClient.client
        .getAsset(entry.sys.id, {
            locale: DEFAULT_LOCALE,
        })
        .then(data => previewClient.mapper(data, context))
        .then(data => {
            return {
                ...entry,
                fields: {
                    ensured: data,
                },
            };
        });
};
