// @flow

import { PageContent } from "@components";
import { LocalizedPreview } from "@containers";
import { Router } from "@reach/router";
import maps from "@utils/preview/maps/generic";
import { graphql } from "gatsby";
import React from "react";

import { mapPageHeader } from "../../../utils/ContentModelMapper";

/**
 * Preview body, could possibly be more generalized
 */
const Body = ({ entryId, token, environment }: any) => {
    const breadcrumb = [
        {
            label: "Home",
            destination: `/en-be`,
            active: false,
        },
        {
            label: "Live preview",
            destination: `/en-be`,
            active: true,
        },
    ];

    return (
        <LocalizedPreview
            id="page-preview"
            maps={maps}
            entryId={entryId}
            token={token}
            environment={environment}
        >
            {(data, _, selectLocale) => {
                const hideCategoryBar =
                    data.pageType !== "article" && data.pageType !== "category";

                const passAllData =
                    data.pageType === "Article" ||
                    data.pageType === "Person" ||
                    data.pageType === undefined;

                return (
                    <PageContent
                        navigation={data.navigation}
                        footer={data.footer}
                        contentAboveEntry={data.contentAboveEntry}
                        header={
                            data.pageHeader &&
                            mapPageHeader({
                                ...data.pageHeader,
                                breadcrumb,
                                backgroundCard: true,
                            })
                        }
                        entry={
                            passAllData
                                ? data
                                : { ...data.entry, slug: data.slug }
                        }
                        contentBelowEntry={data.contentBelowEntry}
                        publishedMarkets={data.markets}
                        offer={data.offer}
                        slug={data.slug}
                        previewLocale={selectLocale}
                        enableScrollTop={data.enableScrollTop}
                        hideCategoryBar={hideCategoryBar}
                        hideCategoryBarMobile={hideCategoryBar}
                    />
                );
            }}
        </LocalizedPreview>
    );
};

const PagePreview = ({ data }: *) => (
    <Router>
        <Body
            path="/preview/page/:entryId/:token"
            environment={data.site.siteMetadata.environment}
        />
    </Router>
);

export default PagePreview;

export const query = graphql`
    {
        site {
            siteMetadata {
                environment
            }
        }
    }
`;
