// @flow

import { asset, page } from "../common";

/**
 * A generic general purpose preview mapper.
 * If a more specific one is needed, create a sibling
 * folder in the parent folder of this map collection
 * at ../../
 */
export default {
    asset,
    page,
    pageLanding: page,
};
