// @flow

/**
 * Detect if processed and stores state in the context
 * Avoids infinite recursing
 */
const hasBeenProcessed = (entry, context) => {
    // Protect agains recursion
    if (!context.processed) {
        context.processed = {};
    }

    if (!context.processed.page) {
        context.processed.page = [];
    }

    if (context.processed.page.includes(entry.sys.id)) {
        return true;
    }

    // Store the id in context
    context.processed.page.push(entry.sys.id);

    return false;
};

/**
 * Creates a page mapper
 */
export default async (entry: *, context: *) => {
    const { previewClient } = context;

    if (hasBeenProcessed(entry, context)) {
        return entry;
    }

    // Auto fill markets when none is defined
    if (!entry.markets) {
        const markets = await previewClient.client.getEntries({
            ...context.query,
            content_type: "market",
        });
        entry.markets = await previewClient.mapper(markets.items, context);
    }

    // Auto fill with the default navigation of the relevant channel
    if (!entry.navigation) {
        const navigation = await previewClient.client.getEntries({
            ...context.query,
            content_type: "navigation",
            // TODO: Filter on channel, fallback for preview
            // TODO: Filter on market, fallback to none filtered
            "fields.default": true,
        });

        // Only map if found
        if (navigation.items.length) {
            entry.navigation = await previewClient.mapper(
                navigation.items[0],
                context,
            );

            entry.navigation.displayCountrySelector =
                entry.navigation.displayCountryIndicator;
        }
    } else {
        entry.navigation.displayCountrySelector =
            entry.navigation.displayCountryIndicator;
    }

    // Auto fill footer with default if none is present
    if (!entry.footer) {
        const footer = await previewClient.client.getEntries({
            ...context.query,
            content_type: "footer",
            "fields.default": true,
            // TODO: Filter on market, fallback to none filtered
        });

        if (footer.items.length) {
            entry.footer = await previewClient.mapper(footer.items[0], context);
        }
    }

    // TODO: Check if promotional offer needs to be mapped
    return entry;
};
